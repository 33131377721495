/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  It contains recoil's selectors files to manage the function for update the state value(s)
  selector : This is an recoil's default library to manage the function to update the global state values, 
             This selector is an function and it recieve parameter as object with containing key, setter and getter function to update and retur values.
  key      : This is an unique key to access this atom function from component.
  get      : This is a function that evaluates the value for the derived state, 
             It may return either a value directly, an asynchronous Promise , a Loadable , or another atom or selector representing the same type.
  set      : Recoil manages atom and selector state changes to know when to notify components subscribing to that selector to re-render.
             If an object value of a selector is mutated directly it may bypass this and avoid properly notifying subscribing components.
*/

import { selector } from 'recoil' //selectorFamily
import { axiosConfiguration } from '../atoms/atoms' //selectedUser, CaseOfUsers
import axios from 'axios'
import { trackPromise } from 'react-promise-tracker'

const fetchDataSelector = selector({
  key: 'fetchDataSelector',
  get: ({ get }) => {
    const config = get(axiosConfiguration)
    if (config && Object.keys(config).length) {
      const fetchData = async (
        method,
        url,
        postData,
        header,
        requestParams,
        enableTrackPromise = true,
        restrictUserAndContract = false
      ) => {
        //const accessToken = ''
        const signInRequired = 'false'
        const axiosInstance = axios.create({
          baseURL: config?.VariableSettings?.CitizenAPIURL ?? '',
          headers: {
            'content-type': 'application/json',
          },
        })
        url = url
          ?.replace('__contractId__', config?.VariableSettings?.ContractId)
          ?.replace('__userId__', config?.VariableSettings?.UserId)

        let requestHeaders = header
        //const useIdValue = ''
        //const useNameValue = ''
        if (signInRequired === 'false') {
          requestHeaders = {
            ...requestHeaders,
            UserType: `Guest`,
          }
        }
        if (!(header && header?.FunctionKey)) {
          requestHeaders = {
            ...requestHeaders,
          }
        }

        const getData = async () => {
          if (method?.toLowerCase() === 'post' && !restrictUserAndContract) {
            postData = {
              ...postData,
              contractId: config?.VariableSettings?.ContractId,
              userId: config?.VariableSettings?.UserId,
            }
          }
          const response = await axiosInstance({
            method,
            url,
            data: postData,
            headers: requestHeaders,
            params: requestParams,
          })
            .then((responseData) => {
              return responseData
            })
            .catch((err) => {
              return err?.response || err
            })
          return response
        }
        if (enableTrackPromise) {
          const response = await trackPromise(getData())
          return response
        } else {
          const response = getData()

          return response
        }
      }
      return fetchData
    }
    return () => {}
  },
})

const bodyStyles = document.body.style

/* istanbul ignore next */
const setAccessibilityOptionSetup = selector({
  key: 'setAccessibilityOptionSetup',
  get: ({ get }) => {
    const accessibilitySetupFinalData = (allElementDetails) => {
      let finalElement = {}
      allElementDetails &&
        allElementDetails?.length > 0 &&
        allElementDetails.forEach((elementDetails) => {
          let selectId = null
          let currentValue = null
          let newElementDetails =
            elementDetails && elementDetails['selectedElementData']
              ? elementDetails?.selectedElementData &&
                !elementDetails['resetOption']
              : elementDetails

          if (elementDetails && elementDetails?.resetOption) {
            selectId = elementDetails?.selectedElementId
            currentValue = elementDetails?.selectedElementValue
            newElementDetails = elementDetails?.selectedElementData
            delete elementDetails['resetOption']
          } else {
            const typeOfAction = elementDetails?.selectedType
            const calcType = elementDetails?.calculateType
            let existValue = 0

            switch (typeOfAction) {
              case 'checkbox':
                currentValue =
                  elementDetails?.selectedElementData?.target?.checked ||
                  elementDetails?.selectedElementValue
                selectId = elementDetails?.selectedElementId
                break
              case 'button':
                currentValue =
                  Object.keys(newElementDetails)?.length > 0
                    ? elementDetails?.target
                      ? !elementDetails?.target?.classList?.contains('active')
                      : !!elementDetails?.selectedElementValue
                    : !!elementDetails?.selectedElementValue
                selectId =
                  elementDetails?.target?.id ||
                  elementDetails?.selectedElementId
                break
              case 'calculateValue':
                existValue = Number(
                  document
                    .getElementById(
                      elementDetails?.target?.parentElement?.classList[1]
                    )
                    ?.innerHTML?.replace('%', '')
                    ?.toString()
                    ?.toLowerCase() === 'default'
                    ? 0
                    : document
                        .getElementById(
                          elementDetails?.target?.parentElement?.classList[1]
                        )
                        ?.innerHTML?.replace('%', '')
                )
                if (calcType === 'increase') {
                  currentValue = existValue + 10
                } else if (calcType === 'decrease') {
                  currentValue = existValue - 10
                }
                if (!currentValue) {
                  currentValue = 'Default'
                }
                selectId = elementDetails?.target?.parentElement?.classList[1]
                break
              case 'colorSelect':
                currentValue = elementDetails?.target?.id
                selectId = elementDetails?.target?.classList[1]
                if (selectId === 'fontcolor') {
                  bodyStyles.setProperty(
                    '--accssibility-selected-fontcolor-pallet',
                    currentValue
                  )
                } else if (selectId === 'titlefontcolor') {
                  bodyStyles.setProperty(
                    '--accssibility-selected-titlefontcolor-pallet',
                    currentValue
                  )
                } else if (selectId === 'bgcolor') {
                  bodyStyles.setProperty(
                    '--accssibility-selected-bgcolor-pallet',
                    currentValue
                  )
                }
                break
              case 'headeroption':
                currentValue = elementDetails?.displayoption
                selectId = elementDetails?.recid
                break
              default:
                currentValue = null
                break
            }
          }

          finalElement = {
            ...finalElement,
            [selectId]: {
              selectedElementId: selectId,
              selectedElementValue: currentValue,
              selectedElementData: newElementDetails,
            },
          }
        })
      return finalElement
    }
    return accessibilitySetupFinalData
  },
})

export { fetchDataSelector, setAccessibilityOptionSetup }
