/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
/*
this component used for togle button componet showed
*/
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Switch } from '@material-ui/core'
import HelpText from '../helpText/HelpText'
import './Switch.css'
import SwitchUnstyled from '@mui/base/SwitchUnstyled' //switchUnstyledClasses,useSwitch
/** import { styled } from '@mui/system' **/

function SwitchControl({
  field,
  value,
  onChangeHandler,
  fieldErrorMessage,
  required,
  componentStyle,
  tabIndex,
  onKeyDown,
  ...rest
}) {
  function onChange(event) {
    if (onChangeHandler)
      onChangeHandler(event, {
        id: field?.fieldValue,
        value: event.target.checked,
      })
  }

  const clickByEnterKey = (e) => {
    if (e?.keyCode === 13) {
      if (onChangeHandler)
        onChangeHandler(e, {
          id: field?.fieldValue,
          value: !e.target.checked,
        })
    }
  }

  useEffect(() => {
    if (required && (value === '' || value === undefined)) {
      onChangeHandler({
        id: field?.fieldValue,
        value: false,
      })
    }
  }, [])

  /* Note:Using two functions (styled and unStyled) switch component
    Note:And different classNames also using */
  return (
    <div
      className={`switch ${rest?.gridControl ? 'gridControl' : ''} ${
        field?.className
      }`}
    >
      {!rest?.gridControl ? (
        <div className="control">
          <label
            className={
              componentStyle === 'styleComponent' ? 'switch__label' : 'unstyle'
            }
            for={`tog_${field && field?.id ? field?.id : field?.fieldLabel}`}
          >
            {field?.fieldLabel}
          </label>
          {field && field.helpText && (
            <span className="icon-padding">
              <HelpText LongText={field.helpText} />
            </span>
          )}
          {required && <span className="Required-color"> *</span>}
        </div>
      ) : null}

      <div className={`switch__input `}>
        {componentStyle === 'styleComponent' ? (
          <div>
            <Switch
              inputProps={{ 'data-testid': 'Switch' }}
              id={`tog_${field && field?.id ? field?.id : field?.fieldLabel}`}
              color="primary"
              checked={value}
              onChange={onChange}
              disabled={field?.disabled}
              size="md"
              tabIndex={tabIndex}
              onKeyDown={(e) => clickByEnterKey(e)}
            />
            {fieldErrorMessage && <span>{fieldErrorMessage}</span>}
          </div>
        ) : (
          /* Note:Material-UI UnStyled switch for using AdditionalTab */
          <div className={`switch_unStyle ${field?.placeholderClassName}`}>
            <SwitchUnstyled
              id={`tog_${field && field?.id ? field?.id : field?.fieldLabel}`}
              data-testid={field?.dataTestId ? field?.dataTestId : 'Switch'}
              checked={value}
              onChange={onChange}
              disabled={field?.disabled}
              size="md"
              tabIndex={tabIndex}
              onKeyDown={(e) => clickByEnterKey(e)}
            />
            <span className={`placeholder `}>{field?.placeholder}</span>
            {fieldErrorMessage && (
              <span className="unStyled">{fieldErrorMessage}</span>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

SwitchControl.defaultProps = {
  field: PropTypes.shape({
    fieldLabel: '',
    // DefaultValue: false,
  }),
  value: false,
  onChangeHandler: () => {},
}

SwitchControl.propTypes = {
  field: PropTypes.shape({
    fieldValue: PropTypes.string,
    fieldLabel: PropTypes.string,
    // DefaultValue: PropTypes.bool,
  }),
  value: PropTypes.bool,
  onChangeHandler: () => null,
}

export default SwitchControl
