/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  In this files we configured router and menubar details
  #MenuLayout     : In this menulayout we mapped all our 
  #BrowserRouter  : BrowserRouter is a router implementation that uses the HTML5 history API (pushstate, replacestate, and popstate events) to keep your UI in sync with the URL.
                    It is the parent component used to store all other components.
  #bodystyles     : Bodystyles used to handle the global css variable to avoid the code duplicate

*/
// System defined variables
import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

// Custom defined variables
import RoutesFile from '../views/routes/Routes'
import ErrorBoundary from '../views/components/custom/errorBoundary/ErrorBoundary'
import CookieBannerWokingham from '../views/components/custom/cookieSettings/CookieBannerWokingham'
import CookieSettingPanel from '../views/components/custom/cookieSettings/CookieSettingPanel'
import Toast from '../views/components/base/toast/Toast'
import BackdropSpinner from '../views/components/base/backdropSpinner/BackdropSpinner'
// import AccessibilityTool from '../views/pages/Accessibility/AccessibilityIndex'
import webFAQ from '../assets/static/progressionIcon/webFAQ.svg'
import tabletFAQ from '../assets/static/progressionIcon/tabletFAQ.svg'
import mobileFAQ from '../assets/static/progressionIcon/mobileFAQ.svg'
import webChallenge from '../assets/static/progressionIcon/webChallenge.svg'
import tabletChallenge from '../assets/static/progressionIcon/tabletChallenge.svg'
import mobileChallenge from '../assets/static/progressionIcon/mobileChallenge.svg'
import Header from '../views/components/custom/client/header/Header'
import Footer from '../views/components/custom/client/footer/Footer'
import {
  axiosConfiguration,
  accessibilityRerender,
  cookiePanel,
} from '../views/components/recoil/atoms/atoms'
import utils from '../utils/utils'
import './App.css'

function App({ configs }) {
  const [, setAxiosConfig] = useRecoilState(axiosConfiguration)
  const configVariable = useRecoilValue(axiosConfiguration)
  const accessibilityRenderingStatus = useSetRecoilState(accessibilityRerender)
  const [showCookiePanel, setShowCookiePanel] = useRecoilState(cookiePanel)
  const [cookieStorage, setCookieStorage] = useState(utils?.getCookies)
  const [showCookieBanner, setShowCookieBanner] = useState(false)
  const [mobileDevice, setMobileDevice] = useState(false)

  useEffect(() => {
    setAxiosConfig({ ...configs })
    const cookieData = utils?.getCookies()
    setCookieStorage(cookieData)
    if (Object.keys(cookieData)?.length > 0) {
      setShowCookieBanner(false)
    } else {
      setShowCookieBanner(true)
    }
  }, [])

  const bodyStyles = document.body.style

  bodyStyles?.setProperty('--default-FontName', `'Open Sans',sans-serif`)

  bodyStyles?.setProperty('--appcontent-bg-color', '#f5f5f5')
  bodyStyles?.setProperty('--font-letter-space', '0px')
  bodyStyles?.setProperty('--text-line-height', 'null')
  bodyStyles?.setProperty('--textahrlignment', 'left')
  bodyStyles?.setProperty('--footertextahrlignment', 'center')
  bodyStyles?.setProperty('--cursorurl', null)
  bodyStyles?.setProperty('--cursorname', null)
  bodyStyles?.setProperty('--required-color', '#ff0000cc')
  bodyStyles?.setProperty('--button-color', '#333333')
  bodyStyles?.setProperty('--button-diabled-color', '#B6B6B6')
  bodyStyles?.setProperty('--gridbgcolor', '#f5f5f5')
  bodyStyles?.setProperty('--default-FontColour', 'black')
  bodyStyles?.setProperty('--label-FontColour', '#333333')
  bodyStyles?.setProperty('--ToolbarTextColour', 'Black')
  bodyStyles?.setProperty('--footer-default-fontcolor', '#5c5b5bbf')
  bodyStyles?.setProperty('--controlborder-colour', 'null')
  bodyStyles?.setProperty('--default-FontWeight', '400')
  bodyStyles?.setProperty('--header-FontWeight', '600')
  bodyStyles?.setProperty('--button-FontWeight', '600')
  bodyStyles?.setProperty('--cookieLabel-FontWeight', '700')

  bodyStyles?.setProperty('--heading-FontColour', 'Black')
  bodyStyles?.setProperty('--ac-heading-FontColour', 'Black')
  bodyStyles?.setProperty('--subHeading-FontColour', '#333333')
  bodyStyles?.setProperty('--listHeader-FontColour', '#798093')
  bodyStyles?.setProperty('--ListHeaderText-Colour', 'black')
  bodyStyles?.setProperty('--TitleBar-TextColour', 'White')
  bodyStyles?.setProperty('--ToolbarBackgroundColour', 'White')
  bodyStyles?.setProperty('--controlBgColor', 'White')
  bodyStyles?.setProperty('--navbar-color', '#F8F8FF')
  bodyStyles?.setProperty('--footer-color', '#F3F3F6')
  bodyStyles?.setProperty('--text-color', '#575A5D')
  bodyStyles?.setProperty('--navbar-group-color', '#F3F3F3')
  bodyStyles?.setProperty('--titlebar-color', '#F3F3F6')
  bodyStyles?.setProperty(
    '--accssibility-selected-fontcolor-pallet',
    '#0000000'
  )
  bodyStyles?.setProperty(
    '--accssibility-selected-titlefontcolor-pallet',
    '#0000000'
  )
  bodyStyles?.setProperty('--accssibility-selected-bgcolor-pallet', '#0000000')
  bodyStyles?.setProperty('--unStyledSwitchColor', '#1F3C88')
  bodyStyles?.setProperty('--ac-unStyledSwitchColor', '#535862')
  bodyStyles?.setProperty('--ac-footerBgColor', '#535862')
  bodyStyles?.setProperty('--textBox-border-color', '#B6B6B6')
  bodyStyles?.setProperty('--inputBox-background-color', '#FFFFFF')
  bodyStyles?.setProperty('--ac-inputBox-background-color', '#FFFFFF')
  bodyStyles?.setProperty('--search-inputBox-height', '43px')
  bodyStyles?.setProperty('--search-inputError-fontsize', '14px')
  bodyStyles?.setProperty('--success-FontColor', 'green')
  bodyStyles?.setProperty('--inProgress-FontColor', '#3366B3')
  bodyStyles?.setProperty('--nextProcess-FontColor', '#676666')

  bodyStyles?.setProperty('--title-font-color', '#333333')
  bodyStyles?.setProperty('--button-font-color', '#ffffff')
  bodyStyles?.setProperty('--expand-font-color', '#6A6A6A')
  bodyStyles?.setProperty('--button-defaultfont-color', '#333333')
  bodyStyles?.setProperty('--payment-declined-color', 'red')
  bodyStyles?.setProperty('--dropzonearea_bg-color', '#fafafa')
  bodyStyles?.setProperty('--button-border-color', '#333333')
  bodyStyles?.setProperty('--faq-font-color', '#1F3C88')
  bodyStyles?.setProperty('--spinner-color', 'black')
  bodyStyles?.setProperty('--cookieBannerCloseDivSize', '24px')
  bodyStyles?.setProperty('--cookieBannerCloseIconSize', '18px')
  bodyStyles?.setProperty('--borderLineColor', '#ECECEC')
  bodyStyles?.setProperty('--paymentSuccessPanelBgColor', '#F0FFF1')
  bodyStyles?.setProperty('--paymentFailurePanelBgColor', '#FFF0F0')
  bodyStyles?.setProperty('--horizontalLineColor', '#B6B6B6')
  bodyStyles?.setProperty('--label-FontColor', '#333333')
  bodyStyles?.setProperty('--progressLineColor', '#3366B3')
  bodyStyles?.setProperty('--disableProgressLineColor', '#DBDBDB')
  bodyStyles?.setProperty('--challengeMessageBgColor', '#ECECEC')
  bodyStyles?.setProperty('--addMessageColor', '#535353')
  bodyStyles?.setProperty('--attachmentNoteColor', '#626262')
  bodyStyles?.setProperty('--attachmentViewerBgColor', '#d3d3d3')
  bodyStyles?.setProperty('--ticketdetails_container_color', '#DEDEDE')
  bodyStyles?.setProperty('--payment_btn_color', '#00703C')
  bodyStyles?.setProperty('--Payment_BG_Color', '#F8F8F8')
  bodyStyles?.setProperty('--Payment_sub_font_color', '#797B7D')
  //tooltip - start
  bodyStyles?.setProperty('--tooltipBackgroundColor', '#838383') //got this color from show&tell #e1dbdb //this color from figma - 838383
  bodyStyles?.setProperty('--tooltipFontColor', '#FFFFFF') // changing from #FFFFFF to #000000 for visibility
  bodyStyles?.setProperty('--tooltipFontSize', '14px')
  bodyStyles?.setProperty('--tooltipBorderRadius', '4px')
  //tooltip - end
  bodyStyles?.setProperty('--tabTitleButton', '#e6e6e6')

  bodyStyles?.setProperty('--dialogContentFontSize', '16px')
  bodyStyles?.setProperty('--dialogContentFontWeight', '400')
  bodyStyles?.setProperty('--motLinkColor', '#438ddb')

  // setTimeout(() => {
  //   const progressImageFAQTag = document?.getElementsByClassName(
  //     'progressionImageFAQSection'
  //   )?.[0]
  //   const progressImageChallengeTag = document?.getElementsByClassName(
  //     'progressionImageChallengeSection'
  //   )?.[0]

  // After veriyfing the below functionality in test, we can remove the commented code
  // setTimeout(() => {
  //   const offsetWidth = document?.body?.offsetWidth
  //   const progressImageFAQTag = document?.getElementsByClassName(
  //     'progressionImageFAQSection'
  //   )?.[0]
  //   const progressImageChallengeTag = document?.getElementsByClassName(
  //     'progressionImageChallengeSection'
  //   )?.[0]

  //   if (offsetWidth >= 300 && offsetWidth < 599) {
  //     if (progressImageFAQTag) progressImageFAQTag.src = mobileFAQ
  //     if (progressImageChallengeTag)
  //       progressImageChallengeTag.src = mobileChallenge
  //   } else if (offsetWidth >= 600 && offsetWidth < 900) {
  //     if (progressImageFAQTag) progressImageFAQTag.src = tabletFAQ
  //     if (progressImageChallengeTag)
  //       progressImageChallengeTag.src = tabletChallenge
  //   }
  //   if (offsetWidth >= 901) {
  //     if (progressImageFAQTag) progressImageFAQTag.src = webFAQ
  //     if (progressImageChallengeTag)
  //       progressImageChallengeTag.src = webChallenge
  //   }
  //}, 100)

  /* istanbul ignore next */
  const setBodyStylesProperty = () => {
    const offsetWidth = document?.body?.offsetWidth
    setTimeout(() => {
      const getHeightOfDataSection =
        document?.getElementsByClassName('caseViewDataSection')?.length > 0 &&
        document
          ?.getElementsByClassName('caseViewDataSection')[0]
          ?.getBoundingClientRect()

      const attachmentDivHeight =
        document?.getElementsByClassName('Attachment')?.length > 0 &&
        document
          ?.getElementsByClassName('Attachment')[0]
          ?.getBoundingClientRect()

      if (
        getHeightOfDataSection &&
        Number(getHeightOfDataSection?.height) <
          Number(attachmentDivHeight?.height)
      ) {
        if (document?.getElementsByClassName('Attachment')?.length > 0) {
          document.getElementsByClassName('Attachment')[0].style.height = `${
            getHeightOfDataSection?.height - 16
          }px`
        }
      }

      const progressImageFAQTag =
        document?.getElementsByClassName('progressionImageFAQSection')?.length >
          0 &&
        document?.getElementsByClassName('progressionImageFAQSection')?.[0]
      const progressImageChallengeTag =
        document?.getElementsByClassName('progressionImageChallengeSection')
          ?.length > 0 &&
        document?.getElementsByClassName(
          'progressionImageChallengeSection'
        )?.[0]

      if (offsetWidth >= 300 && offsetWidth < 599) {
        if (progressImageFAQTag) progressImageFAQTag.src = mobileFAQ
        if (progressImageChallengeTag)
          progressImageChallengeTag.src = mobileChallenge
      } else if (offsetWidth >= 600 && offsetWidth < 900) {
        if (progressImageFAQTag) progressImageFAQTag.src = tabletFAQ
        if (progressImageChallengeTag)
          progressImageChallengeTag.src = tabletChallenge
      }
      if (offsetWidth >= 901) {
        if (progressImageFAQTag) progressImageFAQTag.src = webFAQ
        if (progressImageChallengeTag)
          progressImageChallengeTag.src = webChallenge
      }

      bodyStyles?.setProperty('--ac-colorPalateHeight', '1.75rem')
      bodyStyles?.setProperty('--default-FontSize', '1.05rem')
      bodyStyles?.setProperty('--ac-default-FontSize', '1.05rem')
      bodyStyles?.setProperty('--header-FontSize', '1.75rem')
      bodyStyles?.setProperty('--ac-header-FontSize', '1.75rem')
      bodyStyles?.setProperty('--sub-header-FontSize', '1.5rem')
      bodyStyles?.setProperty('--label-FontSize', '1.05rem')
      bodyStyles?.setProperty('--ac-label-FontSize', '1.05rem')
      bodyStyles?.setProperty('--heading-FontSize', '2rem')
      bodyStyles?.setProperty('--subHeading-FontSize', '1.4rem')
      bodyStyles?.setProperty('--ac-subHeading-FontSize', '1.4rem')
      bodyStyles?.setProperty('--mainheading-FontSize', '1.5rem')
      bodyStyles?.setProperty('--ac-mainheading-FontSize', '1.5rem')
      bodyStyles?.setProperty('--listHeader-FontSize', '1.05rem')
      bodyStyles?.setProperty('--additionalTabSideHead', '1.05rem')
      bodyStyles?.setProperty('--dataHeader-FontSize', '1.05rem')
      bodyStyles?.setProperty('--breadCrumb-SelectedPage', '1.05rem')
      bodyStyles?.setProperty('--brandTitle', '0.9rem')
      bodyStyles?.setProperty('--avatarFontSize', '3rem')
      bodyStyles?.setProperty('--avatarIconSize', '1.75rem')
      bodyStyles?.setProperty('--listHeaderFontSize', '1.05rem')
      /* contractCard */
      bodyStyles?.setProperty('--contractCardTitleFontSize', '1.5rem')
      bodyStyles?.setProperty('--contractCardCountTitleFontSize', '1.25rem')
      bodyStyles?.setProperty('--contractCardCountFontSize', '1.5rem')
      bodyStyles?.setProperty('--contractCardLogoSize', '4.5rem')
      bodyStyles?.setProperty('--contractCardLogoHeight', '3.2rem')
      bodyStyles?.setProperty('--contractCardLogoWidth', '2.25rem')
      bodyStyles?.setProperty('--cookieButton-FontSize', '1.1rem')
      bodyStyles?.setProperty('--cookieSub-FontSize', '1rem')
      /* contractCard */
      /* caseOverView - start */
      bodyStyles?.setProperty('--gridTitleFontSize', '1.25rem')
      bodyStyles?.setProperty('--countValueFontSize', '2rem')
      bodyStyles?.setProperty('--subFontSize', '0.75rem')
      bodyStyles?.setProperty('--buttonFontSizeMobile', '0.8rem')
      bodyStyles?.setProperty('--requiredFontColor', 'red')
      bodyStyles?.setProperty('--vehicleRegistrationNumber', '#ffd633')

      /* caseOverView - end */

      accessibilityRenderingStatus((prevState) => !prevState)
    }, 500)
  }

  const getIsMobileBasedOnScreenSize = () => {
    /* for resolution width 300 to 490 */ /* Mobile */
    const bodyOffsetWidth = document?.body?.offsetWidth
    if (bodyOffsetWidth <= 490 && bodyOffsetWidth >= 300) setMobileDevice(true)
  }

  useEffect(() => {
    getIsMobileBasedOnScreenSize()
    setBodyStylesProperty()
    window?.addEventListener('resize', setBodyStylesProperty)

    // Clean up the event listener on unmount
    return () => window?.removeEventListener('resize', setBodyStylesProperty)
  }, [])

  // const iconImg = configVariable?.VariableSettings?.favIconURL
  /* istanbul ignore next */

  const appInsightsKey = configVariable?.VariableSettings?.AppInsightsKey
  localStorage?.setItem('appInsightsKey', appInsightsKey)

  return (
    <ErrorBoundary>
      <Toast />
      <BackdropSpinner />

      <div className="responsivelayout">
        <Header />
        <div className="contentArea">
          <RoutesFile />
        </div>
        <Footer setShowCookieBanner={setShowCookieBanner} />
        {/* <AccessibilityTool hasCookieBanner={showCookieBanner} /> */}
        {showCookiePanel ? (
          <CookieSettingPanel
            cookieStorage={cookieStorage}
            setCookieStorage={setCookieStorage}
            mobileDevice={mobileDevice}
            showCookiePanel={showCookiePanel}
            setShowCookiePanel={setShowCookiePanel}
          />
        ) : null}
        {showCookieBanner ? (
          <CookieBannerWokingham
            setShowCookieBanner={setShowCookieBanner}
            setCookieStorage={setCookieStorage}
          />
        ) : null}
      </div>
    </ErrorBoundary>
  )
}

export default App
