/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
/* This page contains code for cookie banner - This will show banner once the cookie is not yet selected or banner is not yet closed */

import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import cookieBackgroundImage from '../../../../assets/static/CookieBackgroundImage.svg'
import ButtonComponent from '../../base/button/Button'
import utils from '../../../../utils/utils'
import './CookieBanner.css'
import { useNavigate } from 'react-router'
const CookieBanner = ({ setShowCookieBanner, setCookieStorage }) => {
  const navigate = useNavigate()
  const noThanks = () => {
    const cookieData = {
      acceptcookie: false,
    }
    utils?.setCookies({ ...cookieData })
    setCookieStorage({ ...cookieData })
    onCloseCookieBanner()
  }
  const onCloseCookieBanner = () => {
    setShowCookieBanner(false)
  }
  const acceptAll = () => {
    const cookieData = {
      acceptcookie: true,
    }
    utils?.setCookies({ ...cookieData })
    setCookieStorage({ ...cookieData })
    onCloseCookieBanner()
  }

  return (
    <div className="cookieBanner">
      <div className="bannerDiv">
        <div className="contentDiv">
          <h2 className="bannerDescription">
            We use cookies on this site to enhance your user experience
          </h2>
          <p className="bannerDescriptionSub">
            {`By clicking the Accept button, you agree to us doing so. `}
            <span>
              <a
                data-testid="moreInfo"
                className="hyperLink"
                onClick={() => {
                  navigate('/moreinfo')
                }}
              >
                {`More info`}
              </a>
            </span>
          </p>
        </div>
        <div className="cookieButtons">
          <ButtonComponent
            variant="contained"
            Field={{
              fieldValue: 'Accept',
              fieldLabel: 'Accept',
              cSSClass: 'paybutton',
              type: 'secondary',
              disabled: false,
            }}
            dataTestId="accept"
            onClickHandler={acceptAll}
            tabIndex={75}
          />
          <ButtonComponent
            variant="contained"
            Field={{
              fieldValue: 'No, thanks',
              fieldLabel: 'No, thanks',
              cSSClass: 'paybutton',
              type: 'secondary',
              disabled: false,
            }}
            dataTestId="noThanks"
            onClickHandler={noThanks}
            tabIndex={76}
          />
        </div>
      </div>
    </div>
  )
}
export default CookieBanner
