/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  This component was used to display the loading bar and screen blocker when we make asynchronous process.
  This BackdropSpinner implemented with Recoil library
  This Recoil library is used to handle the global state value
*/

import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import './BackdropSpinner.css'
import { usePromiseTracker } from 'react-promise-tracker'
import { showLoading } from '../../recoil/atoms/atoms'
import { useRecoilValue } from 'recoil'
//props, ref
const BackdropSpinner = () => {
  const { promiseInProgress } = usePromiseTracker({ delay: 500 })
  const showLoader = useRecoilValue(showLoading)

  return (
    <Backdrop
      className="backdrop"
      open={promiseInProgress === true || showLoader}
    >
      <CircularProgress classes={{ root: 'backdrop' }} color="inherit" />
    </Backdrop>
  )
}
export default BackdropSpinner
