/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/* istanbul ignore file */
const config = {}
// import configJson from '../../../src/configs/config.json'
export default config

function load() {
  // Commented to use json through importing file and to restrict file read through url (UnCommented)
  return fetch('config.json')
    .then((result) => result.json())
    .then((newconfig) => {
      for (const prop in config) {
        delete config[prop]
      }
      for (const prop in newconfig) {
        config[prop] = newconfig[prop]
      }
      return config
    })
  // return configJson
}
export { load }
