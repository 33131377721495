/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/* istanbul ignore file */

// System defined variables
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import CryptoJS from 'crypto-js'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'

// Custome defined variables
import Button from '../../components/base/button/Button'
import PaymentSuccessBadge from '../../../assets/static/PaymentSuccessBadge.svg'
import {
  penalityChargeNoticeDetail,
  paymentStatus,
  axiosConfiguration,
  toastMessage,
  paymentDetails,
  paymentURLLoadStatus,
} from '../../components/recoil/atoms/atoms'
import ApiEndPoints from '../../../models/api/apiEndpoints'
import { fetchDataSelector } from '../../components/recoil/selectors/selectors'
import utils from '../../../utils/utils'
import './PaymentSuccess.css'

const PaymentSuccess = () => {
  const [paymentCheckStatus, setPaymentStatus] = useRecoilState(paymentStatus)
  const paymentShowStatus = useRecoilValue(paymentURLLoadStatus)
  const [paymentDetailsValue, setPaymentDetails] =
    useRecoilState(paymentDetails)
  const showToastMessage = useSetRecoilState(toastMessage)
  const getCoreData = useRecoilValue(fetchDataSelector)
  const config = useRecoilValue(axiosConfiguration)
  const navigate = useNavigate()
  const location = useLocation()
  const [hiddenDomValue, setHiddenDomValue] = useState('')
  const [motRoadTaxData, setMotRoadTaxData] = useState({})

  function decrptPaymentDetails(encryptedText, key) {
    const keyBytes = CryptoJS?.enc?.Utf8?.parse(key)
    const dataBytes = CryptoJS?.enc?.Base64?.parse(encryptedText)
    const ivs = CryptoJS?.lib.WordArray?.create(dataBytes?.words?.slice(0, 4))
    const ciphertext = CryptoJS?.lib?.WordArray?.create(
      dataBytes?.words?.slice(4)
    )

    const decryptedBytes = CryptoJS?.AES?.decrypt({ ciphertext }, keyBytes, {
      iv: ivs,
      mode: CryptoJS?.mode?.CBC,
      padding: CryptoJS?.pad?.Pkcs7,
    })

    const decryptedText = CryptoJS?.enc?.Utf8?.stringify(decryptedBytes)
    return decryptedText
  }

  const staticCode = {
    successMessage: 'Your transaction has been successfully processed',
    thankyouMessage:
      'Thank you for your payment. We’ve sent the receipt to your email.',
    paidAmount: 'Paid Amount',
    transactionId: 'Transaction ID',
    transactionDate: 'Transaction Date',
    taxTypes: 'Type',
    taxStatus: 'Status',
    taxExpiry: 'Expiry',
    roadTaxLabel:
      "As a courtesy, we'd like to remind you of the vehicles key information.",
    motText: 'MOT',
    roadTaxText: 'Road Tax',
    validationText: {
      viewCaseDetails: 'viewCaseDetails',
      closeText: 'close',
      ticketDetailsText: 'ticketdetail',
      faqListText: 'faqlist',
      contactDetailText: 'contactdetail',
    },
    motRoadTaxFooterText:
      'To view more information about vehicle MOT status and Road status click here:',
    motStatusText: 'MOT Status',
    roadTaxStatusText: 'Road Tax',
    motStatusURL: 'https://www.gov.uk/check-mot-status',
    roadTaxStatusURL: 'https://www.gov.uk/check-vehicle-tax',
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(location?.search)
    const encryptedRequest = decodeURIComponent(
      urlParams?.get('encryptedRequest')
    )
    if (encryptedRequest !== '' && !paymentCheckStatus) {
      const transactionDetails = decrptPaymentDetails(
        encryptedRequest.replace(/ /g, '+'),
        process.env.APP_PAYMENTKEY
      )
      if (Object.keys(transactionDetails)?.length > 0) {
        if (Object.keys(JSON?.parse(transactionDetails))?.length > 0) {
          setPaymentDetails(JSON?.parse(transactionDetails))
        }
      }
    }
  }, [])

  /* istanbul ignore next */
  const onBackButtonEvent = (e) => {
    e?.preventDefault()
    const paymentShowStatusLocation = paymentShowStatus?.fromLocation
      ?.toString()
      ?.toLowerCase()
    if (
      paymentShowStatusLocation ===
      staticCode?.validationText?.ticketDetailsText
    )
      navigate('/ticketdetails')
    else if (
      paymentShowStatusLocation === staticCode?.validationText?.faqListText
    )
      navigate('/faqlist')
    else if (
      paymentShowStatusLocation ===
      staticCode?.validationText?.contactDetailText
    )
      navigate('/contact')
  }

  /* istanbul ignore next */
  useEffect(() => {
    if (!paymentCheckStatus) setPaymentStatus(true)
    const hiddenDomDateValue = new Date()
    setHiddenDomValue(hiddenDomDateValue)
  }, [])

  /* istanbul ignore next */
  useEffect(() => {
    window?.history?.pushState(null, null, window.location.pathname)
    window?.addEventListener('popstate', onBackButtonEvent)
    return () => window?.removeEventListener('popstate', onBackButtonEvent)
  }, [paymentCheckStatus])

  const [pncdetails, setPenalityChargeNoticeDetail] = useRecoilState(
    penalityChargeNoticeDetail
  )

  function backtoCasedetailsOnclick(buttonType) {
    setPaymentStatus(false)
    if (buttonType === staticCode?.validationText?.viewCaseDetails)
      navigate('/ticketdetails')
    else if (buttonType === staticCode?.validationText?.closeText) navigate('/')
  }

  /* istanbul ignore next */
  const onSearchHandler = async () => {
    const response = await getCoreData(
      ApiEndPoints?.searchCase?.method,
      ApiEndPoints?.searchCase?.url,
      {
        pcnNumber: pncdetails?.penalityChargeNotice,
        vrmNumber: pncdetails?.vehicleRegistrationNumber,
        organisationId: config?.VariableSettings?.OrganisationId,
        contractId: config?.VariableSettings?.ContractId,
        contractType: utils?.contractTypes?.pcnContract,
      }
    )

    if (response?.status === utils?.apiResponseCode?.successCode)
      setPenalityChargeNoticeDetail(response?.data)
    else
      showToastMessage({
        message: response?.data?.message,
        status: utils?.toastStatus?.error,
      })
  }

  useEffect(() => {
    getCoreData(
      ApiEndPoints?.motRoadTaxData?.method,
      ApiEndPoints?.motRoadTaxData?.url,
      {
        registrationNumber: pncdetails?.vehicleRegistrationNumber,
      }
    )?.then((response) => {
      if (response?.status === utils?.apiResponseCode?.successCode)
        setMotRoadTaxData(() => response?.data)
      else {
        setMotRoadTaxData(() => {})
      }
    })
  }, [pncdetails])

  useEffect(() => {
    if (paymentCheckStatus) onSearchHandler()
  }, [paymentCheckStatus])

  return (
    <div className="paymentSuccessMainSection">
      <div className="paymentStatusSuccessPanel">
        <div className="successPanel">
          <div className="caseNumberPanel">
            <div className="caseNumber">
              {`Case Number : ${pncdetails?.penalityChargeNotice
                ?.toString()
                ?.toUpperCase()}`}
            </div>
          </div>
          <div className="greenPanel">
            <div className="successMessagePanel">
              <img
                src={PaymentSuccessBadge}
                className="paymentSuccessBadge"
                alt="paymentSuccessBadge"
              />
              <div className="paymentSuccessMessage">
                <div className="message">{staticCode?.successMessage}</div>
                <div className="paymentThankYouMessage">
                  {staticCode?.thankyouMessage}
                </div>
              </div>
            </div>
            <div className="paymentDetail">
              <div className="content paymentContent">
                <p className="label">{staticCode?.paidAmount}</p>
                <p className="value">
                  {paymentDetailsValue?.amount &&
                    `${pncdetails?.currencySymbol || '£'} ${
                      paymentDetailsValue?.amount
                    }`}
                </p>
              </div>
              <div className="content transactionIDContent">
                <p className="label">{staticCode?.transactionId}</p>
                <p className="value">{paymentDetailsValue?.transactionid}</p>
              </div>
              <div className="content transactionDateContent">
                <p className="label">{staticCode?.transactionDate}</p>
                <p className="value">
                  {paymentDetailsValue?.processeddate?.split(' ')[0]}
                </p>
              </div>
            </div>

            {motRoadTaxData && Object.keys(motRoadTaxData)?.length > 0 && (
              <>
                <div className="horizontalLine" />
                <div className="MOTRoadTaxDetail">
                  <div className="MOTRaodTaxLabel">
                    {staticCode?.roadTaxLabel}
                  </div>
                  <div className="MOTRoadTaxMainSection">
                    <div className="MOTRoadTaxHeaderSection">
                      <div>{staticCode?.taxTypes}</div>
                      <div>{staticCode?.taxStatus}</div>
                      <div>{staticCode?.taxExpiry}</div>
                    </div>
                    <div className="MOTDataSection">
                      <div>{staticCode?.motText}</div>
                      <div>{motRoadTaxData?.motStatus}</div>
                      <div
                        className={`${
                          motRoadTaxData?.motExpiryDate &&
                          motRoadTaxData?.motExpiryDate <
                            utils?.returnCurrentDateTimeBasedOnTimezone(
                              pncdetails?.timeZoneName
                            )
                            ? 'motExpired'
                            : ''
                        }`}
                      >
                        {utils?.convertDateTimeToFormattedDateTime(
                          motRoadTaxData?.motExpiryDate,
                          'Date'
                        )}
                      </div>
                    </div>
                    <div className="RoadTaxDataSection">
                      <div>{staticCode?.roadTaxText}</div>
                      <div>{motRoadTaxData?.taxStatus}</div>
                      <div
                        className={`${
                          motRoadTaxData?.motExpiryDate &&
                          motRoadTaxData?.motExpiryDate <
                            utils?.returnCurrentDateTimeBasedOnTimezone(
                              pncdetails?.timeZoneName
                            )
                            ? 'roadTaxExpired'
                            : ''
                        }`}
                      >
                        {utils?.convertDateTimeToFormattedDateTime(
                          motRoadTaxData?.taxDueDate,
                          'Date'
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="buttonPanel">
              <Button
                variant="contained"
                Field={{
                  fieldValue: 'Back to Case details',
                  fieldLabel: 'View Case Details',
                  cSSClass: '',
                  type: 'secondary',
                  disabled: false,
                }}
                dataTestId="backToCaseDetails"
                onClickHandler={() =>
                  backtoCasedetailsOnclick(
                    staticCode?.validationText?.viewCaseDetails
                  )
                }
              />

              <Button
                variant="contained"
                Field={{
                  fieldValue: 'close',
                  fieldLabel: 'Close',
                  cSSClass: '',
                  type: 'primary',
                  disabled: false,
                }}
                dataTestId="backToCaseSearch"
                onClickHandler={() =>
                  backtoCasedetailsOnclick(
                    staticCode?.validationText?.closeText
                  )
                }
              />
            </div>
          </div>
        </div>
        {motRoadTaxData && Object.keys(motRoadTaxData)?.length > 0 && (
          <div className="motRoadTaxFooterText">
            {staticCode?.motRoadTaxFooterText}{' '}
            <a target="blank" href={staticCode?.motStatusURL}>
              {staticCode?.motStatusText}{' '}
            </a>
            and{' '}
            <a target="blank" href={staticCode?.roadTaxStatusURL}>
              {staticCode?.roadTaxStatusText}
            </a>
          </div>
        )}
        <input id="hiddendiv" className="hiddendiv" value={hiddenDomValue} />
      </div>
    </div>
  )
}

export default PaymentSuccess
