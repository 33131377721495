/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/base/button/Button'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import PaymentFailureBadge from '../../../assets/static/PaymentFailureBadge.svg'
import './PaymentFailure.css'
import {
  penalityChargeNoticeDetail,
  paymentStatus,
  axiosConfiguration,
  paymentURLLoadStatus,
} from '../../components/recoil/atoms/atoms'

const PaymentFailure = () => {
  const [PaymentStatus, setPaymentStatus] = useRecoilState(paymentStatus)
  const paymentShowStatus = useRecoilValue(paymentURLLoadStatus)
  const setPaymentShowStatus = useSetRecoilState(paymentURLLoadStatus)
  const config = useRecoilValue(axiosConfiguration)

  const pncdetails = useRecoilValue(penalityChargeNoticeDetail)
  const navigate = useNavigate()

  /* istanbul ignore next */
  const onBackButtonEvent = (e) => {
    e.preventDefault()

    if (
      paymentShowStatus?.fromLocation?.toString()?.toLowerCase() ===
      'ticketdetail'
    ) {
      navigate('/ticketdetails')
    } else if (
      paymentShowStatus?.fromLocation?.toString()?.toLowerCase() === 'faqlist'
    ) {
      navigate('/faqlist')
    } else if (
      paymentShowStatus?.fromLocation?.toString()?.toLowerCase() ===
      'contactdetail'
    ) {
      navigate('/contact')
    }
  }

  /* istanbul ignore next */
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname)
    window.addEventListener('popstate', onBackButtonEvent)
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }
  }, [PaymentStatus])

  // useEffect(() => {
  //   if (!PaymentStatus) {
  //     // window.parent.location.href = config?.VariableSettings?.CitizenFailURL
  //     setPaymentStatus()
  //   }
  // }, [])

  return (
    <div className="paymentStatusFailurePanel">
      <div className="failurePanel">
        <div className="caseNumberPanel">
          <p className="caseNumber">
            {pncdetails?.penalityChargeNotice?.toString()?.toUpperCase()}
          </p>
        </div>
        <div className="redPanel">
          <div className="failureMessagePanel">
            <img
              src={PaymentFailureBadge}
              className="paymentFailureBadge"
              alt="paymentFailureBadge"
            ></img>
            <p className="message">Payment Declined</p>
            <p className="content">
              Please check your card details and try again.
            </p>
          </div>

          <div className="buttonPanel">
            <Button
              variant="contained"
              Field={{
                fieldValue: 'btn_Pay',
                fieldLabel: 'Retry Payment',
                cSSClass: '',
                type: 'primary',
                disabled: false,
              }}
              dataTestId="retryPayment"
              onClickHandler={() => {
                // setPaymentStatus(false)
                setPaymentShowStatus((prevState) => ({
                  ...prevState,
                  payNowClicked: true,
                }))
                navigate('/payment')
              }}
            />
            <Button
              variant="contained"
              Field={{
                fieldValue: 'Back to Case details',
                fieldLabel: 'Back to Case details',
                cSSClass: '',
                type: 'primary',
                disabled: false,
              }}
              dataTestId="backToCaseDetails"
              onClickHandler={() => {
                // setPaymentStatus(false)
                navigate('/ticketdetails')
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentFailure
