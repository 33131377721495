/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
/* This page contains code for setting cookies for application */
/* The cookie description used here is for sample. Real application cookies will develop after this */

import React, { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import CloseIcon from '@mui/icons-material/Close'
import Add from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import ButtonComponent from '../../base/button/Button'
import Switch from '../../base/switch/Switch'
import npsLogo from '../../../../assets/static/Marston_NPS.svg'
import utils from '../../../../utils/utils'
import './CookieSettingPanel.css'
const CookieSettingPanel = ({
  cookieStorage,
  setCookieStorage,
  mobileDevice,
  setShowCookiePanel,
}) => {
  const [expanded, setExpanded] = useState({
    necessary: false,
    performance: false,
    functional: false,
    targeting: false,
  })
  const [cookieSettingsData, setCookieSettingsData] = useState({
    ...cookieStorage,
  })
  const [scrollClassNAme, setScrollClassName] = useState('hideScrollBar')
  const viewDescription = (expandingKey) => {
    setExpanded({
      ...expanded,
      [expandingKey]: !Boolean(expanded[expandingKey]),
    })
  }
  const onChangeHandler = (event, params) => {
    setCookieSettingsData({
      ...cookieSettingsData,
      [params?.id]: params?.value,
    })
  }
  const acceptAll = () => {
    setCookieSettingsData({
      performancecookie: true,
      functionalcookie: true,
      targetingcookie: true,
    })
  }
  const rejectAll = () => {
    setCookieSettingsData({
      performancecookie: false,
      functionalcookie: false,
      targetingcookie: false,
    })
  }
  const confirmChoice = () => {
    utils?.setCookies({ ...cookieSettingsData })
    setCookieStorage({ ...cookieSettingsData })
    closeCookiePanel()
  }
  const closeCookiePanel = () => {
    setShowCookiePanel(false)
  }
  useEffect(() => {
    var panelContentScrollable = document.getElementById(
      'panelContentScrollable'
    )
    var hasVerticalScrollbar =
      panelContentScrollable.scrollHeight > panelContentScrollable.clientHeight
    if (hasVerticalScrollbar) setScrollClassName('showScrollBar')
  }, [expanded])

  useEffect(() => {
    setCookieSettingsData(cookieStorage)
  }, [cookieStorage])

  const clickCloseCookiePanel = (e) => {
    if (e?.keyCode === 13) {
      setCookieSettingsData({ ...cookieStorage })
      closeCookiePanel()
    }
  }

  const expandDescription = (e, clickedDiv) => {
    if (e?.keyCode === 13) {
      viewDescription(clickedDiv)
    }
  }

  return (
    <div className="cookieSettingBackground">
      <div className="cookieSettingPanel">
        <div className="panelHeader">
          <div className="logoDiv">
            <img
              alt="marston-Logo"
              className="cookieSettingPanelLogo"
              src={npsLogo}
            />
          </div>
          <div
            className="closeIconDiv"
            data-testid="closeCookieSettingPanel"
            onClick={() => {
              //reset to cookieStorage when close without save
              setCookieSettingsData({ ...cookieStorage })
              closeCookiePanel()
            }}
            onKeyDown={(e) => clickCloseCookiePanel(e)}
            tabIndex={74}
          >
            <CloseIcon className="panel_muiIcon" />
          </div>
        </div>
        <div
          className={`panelContent ${scrollClassNAme}`}
          id="panelContentScrollable"
        >
          <div className="policyDiv">
            <p className="policyTitle">Cookies Policy</p>
            <p className="policyContent">
              When you visit any website, it may store or retrieve information
              on your browser, mostly in the form of cookies. This information
              might be about you, your preferences or your device and is mostly
              used to make the site work as you expect it to. The information
              does not usually directly identify you, but it can give you a more
              personalized web experience. Because we respect your right to
              privacy, you can choose not to allow some types of cookies. Click
              on the different category headings to find out more and change our
              default settings. However, blocking some types of cookies may
              impact your experience of the site and the services we are able to
              offer.
            </p>
          </div>
          <div className="manageDiv">
            <div className="manageHeaderDiv">
              <p className="manageTitle">Manage Consent Preference</p>
              {!mobileDevice ? (
                <ButtonComponent
                  variant="contained"
                  Field={{
                    fieldValue: 'Accept All',
                    fieldLabel: 'Accept All',
                    cSSClass: 'paybutton',
                    type: 'primary',
                    disabled: false,
                  }}
                  dataTestId="acceptAll"
                  onClickHandler={acceptAll}
                  tabIndex={78}
                />
              ) : null}
            </div>
            <div className="manageContentDiv">
              <div className="settingDiv">
                <div
                  className="expandCollapseIconDiv"
                  onClick={() => {
                    viewDescription('necessary')
                  }}
                  data-testid="expandNecessary"
                  tabIndex={79}
                  onKeyDown={(e) => expandDescription(e, 'necessary')}
                >
                  {expanded?.necessary ? (
                    <RemoveIcon className="panel_muiIcon" />
                  ) : (
                    <Add className="panel_muiIcon" />
                  )}
                </div>
                <div className="settingContentDiv">
                  <div className="settingTitleDiv">
                    <p className="settingTitle">Strictly Necessary Cookies</p>
                    <Switch
                      componentStyle="styleComponent"
                      field={{
                        id: 'necessaryCookie',
                        fieldLabel: 'necessaryCookie',
                        fieldValue: 'necessaryCookie',
                        className: '',
                        disabled: true,
                      }}
                      onChangeHandler={onChangeHandler}
                      value={true}
                      tabIndex={80}
                      onKeyDown={(e) => {
                        if (e?.keyCode === 13) onChangeHandler
                      }}
                    />
                  </div>
                  {expanded?.necessary ? (
                    <div className="settingDescription">
                      These cookies are necessary for the website to function
                      and cannot be switched off in our systems. They are
                      usually only set in response to actions made by you which
                      amount to a request for services, such as setting your
                      privacy preferences, logging in or filling in forms. You
                      can set your browser to block or alert you about these
                      cookies, but some parts of the site will not then work.
                      These cookies do not store any personally identifiable
                      information.
                    </div>
                  ) : null}
                </div>
              </div>
              <hr className="horizontalSeparator" />
              <div className="settingDiv">
                <div
                  className="expandCollapseIconDiv"
                  onClick={() => {
                    viewDescription('performance')
                  }}
                  data-testid="expandPerformance"
                  tabIndex={81}
                  onKeyDown={(e) => expandDescription(e, 'performance')}
                >
                  {expanded?.performance ? (
                    <RemoveIcon className="panel_muiIcon" />
                  ) : (
                    <Add className="panel_muiIcon" />
                  )}
                </div>
                <div className="settingContentDiv">
                  <div className="settingTitleDiv">
                    <p className="settingTitle">Performance Cookies</p>
                    <Switch
                      componentStyle="styleComponent"
                      field={{
                        id: 'performancecookie',
                        fieldLabel: 'performancecookie',
                        fieldValue: 'performancecookie',
                        className: 'enabled',
                        disabled: false,
                      }}
                      onChangeHandler={onChangeHandler}
                      value={cookieSettingsData?.performancecookie}
                      tabIndex={82}
                    />
                  </div>
                  {expanded?.performance ? (
                    <div className="settingDescription">
                      These cookies allow us to count visits and traffic sources
                      so we can measure and improve the performance of our site.
                      They help us to know which pages are the most and least
                      popular and see how visitors move around the site. All
                      information these cookies collect is aggregated and
                      therefore anonymous. If you do not allow these cookies we
                      will not know when you have visited our site, and will not
                      be able to monitor its performance.
                    </div>
                  ) : null}
                </div>
              </div>
              <hr className="horizontalSeparator" />
              <div className="settingDiv">
                <div
                  className="expandCollapseIconDiv"
                  onClick={() => {
                    viewDescription('functional')
                  }}
                  data-testid="expandFunctional"
                  tabIndex={83}
                  onKeyDown={(e) => expandDescription(e, 'functional')}
                >
                  {expanded?.functional ? (
                    <RemoveIcon className="panel_muiIcon" />
                  ) : (
                    <Add className="panel_muiIcon" />
                  )}
                </div>
                <div className="settingContentDiv">
                  <div className="settingTitleDiv">
                    <p className="settingTitle">Functional Cookies</p>
                    <Switch
                      componentStyle="styleComponent"
                      field={{
                        id: 'functionalcookie',
                        fieldLabel: 'functionalcookie',
                        fieldValue: 'functionalcookie',
                        className: 'enabled',
                        disabled: false,
                      }}
                      onChangeHandler={onChangeHandler}
                      value={cookieSettingsData?.functionalcookie}
                      tabIndex={84}
                    />
                  </div>
                  {expanded?.functional ? (
                    <div className="settingDescription">
                      These cookies enable the website to provide enhanced
                      functionality and personalisation. They may be set by us
                      or by third party providers whose services we have added
                      to our pages. If you do not allow these cookies then some
                      or all of these services may not function properly.
                    </div>
                  ) : null}
                </div>
              </div>
              <hr className="horizontalSeparator" />
              <div className="settingDiv">
                <div
                  className="expandCollapseIconDiv"
                  onClick={() => {
                    viewDescription('targeting')
                  }}
                  data-testid="expandTargeting"
                  tabIndex={85}
                  onKeyDown={(e) => expandDescription(e, 'targeting')}
                >
                  {expanded?.targeting ? (
                    <RemoveIcon className="panel_muiIcon" />
                  ) : (
                    <Add className="panel_muiIcon" />
                  )}
                </div>
                <div className="settingContentDiv">
                  <div className="settingTitleDiv">
                    <p className="settingTitle">Targeting Cookies</p>
                    <Switch
                      componentStyle="styleComponent"
                      field={{
                        id: 'targetingcookie',
                        fieldLabel: 'targetingcookie',
                        fieldValue: 'targetingcookie',
                        className: 'enabled',
                        disabled: false,
                      }}
                      onChangeHandler={onChangeHandler}
                      value={cookieSettingsData?.targetingcookie}
                      tabIndex={86}
                    />
                  </div>
                  {expanded?.targeting ? (
                    <div className="settingDescription">
                      These cookies may be set through our site by our
                      advertising partners. They may be used by those companies
                      to build a profile of your interests and show you relevant
                      adverts on other sites. They do not store directly
                      personal information, but are based on uniquely
                      identifying your browser and internet device. If you do
                      not allow these cookies, you will experience less targeted
                      advertising.
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {!mobileDevice ? (
          <div className="panelFooter">
            <ButtonComponent
              variant="contained"
              Field={{
                fieldValue: 'Reject All',
                fieldLabel: 'Reject All',
                cSSClass: 'paybutton',
                type: 'primary',
                disabled: false,
              }}
              dataTestId="rejectAll"
              onClickHandler={rejectAll}
              tabIndex={87}
            />
            <ButtonComponent
              variant="contained"
              Field={{
                fieldValue: 'Confirm My Choices',
                fieldLabel: 'Confirm My Choices',
                cSSClass: 'paybutton',
                type: 'primary',
                disabled: false,
              }}
              dataTestId="confirmMyChoices"
              onClickHandler={confirmChoice}
              tabIndex={88}
            />
          </div>
        ) : null}
        {mobileDevice ? (
          <div className="panelFooterMobile">
            <div className="acceptAllDiv">
              <ButtonComponent
                variant="contained"
                Field={{
                  fieldValue: 'Accept All',
                  fieldLabel: 'Accept All',
                  cSSClass: 'paybutton',
                  type: 'primary',
                  disabled: false,
                }}
                dataTestId="acceptAllMobile"
                onClickHandler={acceptAll}
                tabIndex={89}
              />
            </div>
            <div className="footerButtonsDiv">
              <ButtonComponent
                variant="contained"
                Field={{
                  fieldValue: 'Reject All',
                  fieldLabel: 'Reject All',
                  cSSClass: 'paybutton',
                  type: 'primary',
                  disabled: false,
                }}
                dataTestId="rejectAllMobile"
                onClickHandler={rejectAll}
                tabIndex={90}
              />
              <ButtonComponent
                variant="contained"
                Field={{
                  fieldValue: 'Confirm My Choices',
                  fieldLabel: 'Confirm My Choices',
                  cSSClass: 'paybutton',
                  type: 'primary',
                  disabled: false,
                }}
                dataTestId="confirmMyChoicesMobile"
                onClickHandler={confirmChoice}
                tabIndex={91}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
export default CookieSettingPanel
