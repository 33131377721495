/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  In this component we write code for configure the props with button component
  onClickHandler  : onClickHandler is received from parent component to pass the button click functionality using this callback function method
  className       : className is received from parent component to aplly the styles of the button component
  Field           : Field is received from parent component to configure the value of button component
*/

import React from 'react'
import { Button } from '@mui/material'
import PropTypes from 'prop-types'

import './Button.css'

const ButtonComponent = ({
  Field,
  className,
  onClickHandler,
  onMouseDownHandler,
  onMouseUpHandler,
  tabIndex,
  onKeyDownHandler,
  ...rest
}) => {
  return (
    <Button
      id={Field?.fieldValue}
      disableRipple
      disableElevation
      className={`button btn--${Field?.type || 'primary'} ${className || ''}`}
      variant={Field && Field?.variant}
      disabled={Field?.disabled}
      onClick={onClickHandler}
      style={Field?.btnStyle ?? {}}
      onMouseDown={onMouseDownHandler}
      onMouseUp={onMouseUpHandler}
      data-testid={`${rest?.dataTestId ? rest?.dataTestId : 'button'}`}
      tabIndex={tabIndex}
      onKeyDown={onKeyDownHandler}
    >
      {Field?.fieldLabel}
    </Button>
  )
}

ButtonComponent.propTypes = {
  Field: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
  onClickHandler: PropTypes.func,
  onMouseDownHandler: PropTypes.func,
  onMouseUpHandler: PropTypes.func,
}

ButtonComponent.defaultProps = {
  Field: {},
  className: '',
  onClickHandler: () => null,
  onMouseDownHandler: () => null,
  onMouseUpHandler: () => null,
}

export default ButtonComponent
