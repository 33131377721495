/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  It contains recoil's atoms files to manage the state value(s)
  atom  : This is an recoil's default library to manage the global state values, This atom is an function and it recieve parameter as object with containing key default values.
  key   : This is an unique key to access this atom function from component.
*/

import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
  key: 'recoil-persist', // this key is using to store data in storage
  storage: sessionStorage, // configurate which stroage will be used to store the data
})

const toastMessage = atom({
  key: 'toastMessage',
  default: { message: '', status: '' },
})

const showLoading = atom({
  key: 'showLoading',
  default: false,
})

const axiosConfiguration = atom({
  key: 'axiosConfiguration',
  default: null,
})

const penalityChargeNoticeDetail = atom({
  key: 'penalityChargeNoticeDetail',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

const searchParamsResultAtom = atom({
  key: 'searchParamsResultAtom',
  default: {
    showError: false,
    penalityChargeNotice: '',
    vehicleRegistrationNumber: '',
  },
})

const captchaInputClear = atom({
  key: 'captchaInputClear',
  default: false,
})

const PaymentResponse = atom({
  key: 'PaymentResponse',
  default: '',
})

const AccessibilityOptions = atom({
  key: 'AccessibilityOptions',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

const CursorPositionData = atom({
  key: 'CursorPositionData',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

const accessibilityRerender = atom({
  key: 'accessibilityRerender',
  default: false,
  effects_UNSTABLE: [persistAtom],
})

const pageRenderStatus = atom({
  key: 'pageRenderStatus',
  default: false,
  effects_UNSTABLE: [persistAtom],
})
const timezoneAtom = atom({
  key: 'timezoneAtom',
  default: {
    //used when search different contract's case through global search
    searchedCase: {
      timezoneName: 'GMT Standard Time',
      timezoneId: 'Europe/London', //used for conversion in dayjs
      timezoneOffset: '',
    },
    default: {
      timezoneName: 'GMT Standard Time',
      timezoneId: 'Europe/London', //used for conversion in dayjs
      timezoneOffset: '',
    },
  },
  effects_UNSTABLE: [persistAtom],
})

const cookiePanel = atom({ key: 'cookiePanel', default: false })

const paymentStatus = atom({
  key: 'paymentStatus',
  default: false,
  effects_UNSTABLE: [persistAtom],
})

const paymentDetails = atom({
  key: 'paymentDetails',
  default: {},
  effects_UNSTABLE: [persistAtom],
})

//used to fetch current data from api and set to existing atom, instead using the existing atom data
const fetchDataFromApiAtom = atom({
  key: 'fetchDataFromApiAtom',
  default: false,
  effects_UNSTABLE: [persistAtom],
})

const paymentURLLoadStatus = atom({
  key: 'paymentURLLoadStatus',
  default: { fromLocation: null, payNowClicked: false },
  effects_UNSTABLE: [persistAtom],
})

export {
  toastMessage,
  showLoading,
  axiosConfiguration,
  penalityChargeNoticeDetail,
  searchParamsResultAtom,
  captchaInputClear,
  PaymentResponse,
  AccessibilityOptions,
  CursorPositionData,
  accessibilityRerender,
  pageRenderStatus,
  timezoneAtom,
  cookiePanel,
  paymentStatus,
  paymentDetails,
  fetchDataFromApiAtom,
  paymentURLLoadStatus,
}
