/*
 This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC),
 is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
 */

/*
  This header component developed for display the contract logo and name
*/
import React from 'react'
import './Header.css'
import headerLogo from './logo.svg'
import { Grid } from '@material-ui/core'

const Header = () => {
  return (
    <div className="header">
      <Grid container spacing={1}>
        {/* <Grid item xs={1} sm={1} md={2} lg={3} xl={4}></Grid> */}
        <Grid item xs={11} sm={10} md={8} lg={6} xl={4}>
          <div className="container">
            <div className="header__primary">
              <div className="header__logo">
                <a href="http://wokingham.gov.uk/">
                  {headerLogo?.toString()?.trim()?.length > 0 && (
                    <img
                      alt="Wokingham Council logo"
                      className="img-logo"
                      src={headerLogo}
                    />
                  )}
                </a>
              </div>
            </div>
          </div>
        </Grid>
        {/* <Grid item xs={1} sm={1} md={2} lg={3} xl={4}></Grid> */}
      </Grid>
    </div>
  )
}
export default Header
