/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  In this file, we configured URL and componenet for that URL
  #lazy           : Lazy loading is a technique that enables us to load a specific component when a particular route is accessed. 
                    It exponentially enhances the load time and the loading speed. At the same time, it increases the react application performance.
  #React.Suspense : React Suspense here to offer a fallback React element when the component is lazy loaded from the server.
*/

import React, { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import PaymentFailure from '../pages/payment/PaymentFailure'
import PaymentSuccess from '../pages/payment/PaymentSuccess'

const Contactdetail = lazy(() =>
  import('../pages/contactDetails/ContactDetail')
)
const Payment = lazy(() => import('../pages/payment/Payment'))
const FaqList = lazy(() => import('../pages/faqList/FaqList'))
const RouteMiddleWare = lazy(() => import('./RoutesMiddleWare'))
const SearchForCase = lazy(() => import('../pages/searchForCase/SearchForCase'))
const TicketDetails = lazy(() => import('../pages/ticketDetails/TicketDetails'))
const ChallengeThankyouPage = lazy(() =>
  import('../pages/challengeThankyouPage/ChallengeThankyouPage')
)
const ThankyouPage = lazy(() => import('../pages/thankyouPage/ThankyouPage'))
const PageNotFound = lazy(() => import('../pages/pageNotFound/PageNotFound'))
const CookieMoreInfo = lazy(() =>
  import('../components/custom/cookieSettings/CookieMoreInfo')
)

const renderProtectedComponent = (
  component,
  routeValidation,
  redirectComponent
) => {
  return (
    <RouteMiddleWare
      routeValidation={routeValidation}
      redirectComponent={redirectComponent}
    >
      {component}
    </RouteMiddleWare>
  )
}

const RoutesFile = () => {
  return (
    <React.Suspense fallback={<p className="loadingblock"></p>}>
      <Routes>
        <Route
          exact
          path="/"
          element={renderProtectedComponent(<SearchForCase />, false, null)}
        />
        <Route
          exact
          path="/contact"
          element={renderProtectedComponent(
            <Contactdetail />,
            true,
            <SearchForCase />
          )}
        />
        <Route
          exact
          path="/faqlist"
          element={renderProtectedComponent(
            <FaqList />,
            true,
            <SearchForCase />
          )}
        />
        <Route
          exact
          path="/ticketdetails"
          element={renderProtectedComponent(
            <TicketDetails />,
            true,
            <SearchForCase />
          )}
        />
        <Route
          exact
          path="/thankyou"
          element={renderProtectedComponent(
            <ChallengeThankyouPage />,
            true,
            <SearchForCase />
          )}
        />
        <Route
          exact
          path="/thankyoupage"
          element={renderProtectedComponent(
            <ThankyouPage />,
            true,
            <SearchForCase />
          )}
        />
        <Route
          exact
          path="/payment-success"
          element={renderProtectedComponent(
            <PaymentSuccess />,
            true,
            <SearchForCase />
          )}
        />
        <Route
          exact
          path="/payment-failure"
          element={renderProtectedComponent(
            <PaymentFailure />,
            true,
            <SearchForCase />
          )}
        />
        <Route
          exact
          path="/payment"
          element={renderProtectedComponent(
            <Payment />,
            true,
            <SearchForCase />
          )}
        />
        <Route
          exact
          path="/moreinfo"
          element={renderProtectedComponent(<CookieMoreInfo />)}
        />
        <Route path="*" element={renderProtectedComponent(<PageNotFound />)} />
      </Routes>
    </React.Suspense>
  )
}

export default RoutesFile
