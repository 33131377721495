/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  This file used to store the API urls thourgh the application in JSON object format
  EX: https://jsonplaceholderqa.typicode.com/api/GetHomeData
*/
/* Added contractId and userId though URL for all 'Get API' */

const api = { baseApi: '/api/' }
const apiMethods = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
}
const APIEndPoints = {
  searchCase: {
    url: `${api?.baseApi}Case/search`,
    method: apiMethods?.post,
    methodname: 'searchCase',
  },
  Country: {
    url: `${api?.baseApi}Country`,
    method: apiMethods?.get,
    methodname: 'Country',
  },
  challenge: {
    url: `${api?.baseApi}Challenge`,
    method: apiMethods?.post,
    methodname: 'challenge',
  },
  userTitle: {
    url: `${api?.baseApi}User/Title`,
    method: apiMethods?.get,
    methodname: 'userTitle',
  },
  Payment: {
    url: `${api?.baseApi}Payment`,
    method: apiMethods?.post,
    methodname: 'Payment',
  },
  ChallengeFAQ: {
    url: `${api?.baseApi}Challenge/FAQ/Contravention/__contraventionID__/User/__userId__/Contract/__contractId__`,
    method: apiMethods?.get,
    methodname: 'ChallengeFAQ',
  },
  captchaVerify: {
    url: `${api?.baseApi}Captcha/Verify`,
    method: apiMethods?.post,
    methodname: 'Captcha Verify',
  },
  documentsView: {
    url: `${api?.baseApi}Correspondence/User/__userId__/Contract/__contractId__/DocumentsView`,
    method: apiMethods?.post,
    methodname: 'DocumentsView',
  },
  motRoadTaxData: {
    url: `${api?.baseApi}DVLA/VehicleEnquiryRequest`,
    method: apiMethods?.post,
    methodname: 'Get the MOT and Road Tax details',
  },
  logSave: {
    url: `${api?.baseApi}Case/Contract/__contractId__/CaseSearchEvent`,
    method: apiMethods?.post,
    methodname: 'Log Save',
  },
}

export default APIEndPoints
