/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/* istanbul ignore file */

import { Component } from 'react'
import { load } from './config'

export default class ConfigLoader extends Component {
  constructor(props) {
    super(props)
    this.state = { isLoaded: false }
  }

  componentDidMount() {
    // Once the configuration is loaded set `isLoaded` to true so we know to render our component (UnCOmmented)
    load().then((config) => this.setState({ isLoaded: true, config }))
    // return this.setState({ isLoaded: true, config: { ...load() } })
  }

  render() {
    const { isLoaded, config } = this.state
    const { loading, ready } = this.props
    // If we haven't yet loaded the config, show either a "splash" component provided via a `loading` props or return nothing.
    if (!isLoaded) {
      return loading ? loading() : null
    }

    // The config is loaded so show the component set on the `ready()` props
    return ready(config)
  }
}
