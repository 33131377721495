/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
    #utils  : in this file, we used common functionalities like, formating, convertion, mapping, etc..
*/

// System defined variables
import moment from 'moment-timezone'
import { DateTime } from 'luxon'

// function to sort string and numeric values in array of objects based on a key -- start
function sortArrayOfObjects(arrayOfObjectsToSort, sortByKey) {
  let arrayOfObjects = arrayOfObjectsToSort
  // If Array has NONE property Shift before sort and unshift after sort
  let hasNoneProperty = false
  if (
    arrayOfObjects &&
    arrayOfObjects?.length > 0 &&
    (arrayOfObjects[0]?.Name === '-- None --' ||
      arrayOfObjects[0]?.id === '-- None --')
  ) {
    hasNoneProperty = true
    arrayOfObjects.shift()
  }
  const stringArr = [] // will have null, undefined and others
  const numericArr = [] //will have numeric data
  arrayOfObjects?.forEach((obj) => {
    //Separate arrayOfObjects data to numeric and string array
    if (
      obj[sortByKey] !== undefined &&
      obj[sortByKey] !== null &&
      isNaN(obj[sortByKey]?.toString().trim() ? obj[sortByKey] : '_') // since IsNaN('  ') returns  -> false, making it true with underscore
    )
      stringArr.push(obj)
    else numericArr.push(obj)
  })

  // string sort -> if same string -> small case will have higher priority
  stringArr.sort((a, b) => {
    const data =
      a[sortByKey] && a[sortByKey] !== null && a[sortByKey] !== undefined
        ? a[sortByKey]?.toString()
        : null
    const compareData =
      b[sortByKey] && b[sortByKey] !== null && b[sortByKey] !== undefined
        ? b[sortByKey]?.toString()
        : null

    if (data?.toLowerCase() === compareData?.toLowerCase()) {
      let returnData = 0
      for (
        let dataCharIndex = 0;
        dataCharIndex < data?.length;
        dataCharIndex++
      ) {
        for (
          let compareDataCharIndex = 0;
          compareDataCharIndex < compareData?.length;
          compareDataCharIndex++
        ) {
          if (
            data.charCodeAt(data[dataCharIndex]) <
            compareData.charCodeAt(compareData[compareDataCharIndex])
          ) {
            compareDataCharIndex = compareData?.length
            dataCharIndex = data?.length
            returnData = 1
          }
          if (
            data.charCodeAt(data[dataCharIndex]) >
            compareData.charCodeAt(compareData[compareDataCharIndex])
          ) {
            compareDataCharIndex = compareData?.length
            dataCharIndex = data?.length
            returnData = -1
          }
        }
      }
      return returnData
    }
    if (data?.toLowerCase() < compareData?.toLowerCase()) return -1
    if (data?.toLowerCase() > compareData?.toLowerCase()) return 1
  })

  // numeric sort
  numericArr.sort((a, b) => {
    return parseFloat(a[sortByKey]) - parseFloat(b[sortByKey])
  })
  Array.prototype.push.apply(numericArr, stringArr)
  arrayOfObjects = numericArr

  if (hasNoneProperty)
    arrayOfObjects.unshift({ Name: '-- None --', id: '-- None --' })
  return arrayOfObjects
}
// function to sort string and numeric values in array of objects based on a key -- end

/* This function was developed for alert show to user when they goback on edited stage */

const handleBeforeUnload = (e) => {
  e.preventDefault()
  const message =
    'Are you sure you wish to go back? You will lose all entered information.'
  e.returnValue = message
  return message
}

const confirmationText =
  'Are you sure you wish to go back? You will lose all entered information.'
const confirmwindow = (conformText) => {
  /* istanbul ignore next */
  if (
    window.confirm(
      conformText ||
        'Are you sure you wish to go back? You will lose all entered information.'
    )
  ) {
    return true
  } else {
    return false
  }
}
const utcDateFormat = (PickerType) => {
  if (PickerType === 'Date') {
    return 'yyyy-MM-dd'
  }
  return 'yyyy-MM-dd HH:mm:ss'
}
const commonDateTimeFormat = (PickerType) => {
  // const localeFormatStr = localStorage.getItem('localeDate')
  if (PickerType === 'Date') {
    let dateFormat = 'dd/MM/yyyy'
    // localeFormat?.localeDateFormat[localeFormatStr] ?? 'dd/MM/yyyy'
    return dateFormat
  }
  if (PickerType === 'DateAndTime') {
    let dateTimeFormat = 'DD/MM/yyyy HH:mm'
    // localeFormat?.localeDateTimeFormat[localeFormatStr] ??
    //'dd/MM/yyyy HH:mm:ss'
    return dateTimeFormat
  }
  if (PickerType === 'Time') {
    let dateTimeFormat = 'HH:mm'
    // localeFormat?.localeDateTimeFormat[localeFormatStr] ??
    // 'HH:mm'
    return dateTimeFormat
  }
  if (PickerType === 'weekDay') return 'dddd'
  if (PickerType === 'dayWithOrder') return 'Do'
  if (PickerType === 'monthText') return 'MMMM'
  if (PickerType === 'time12HrWithoutSec') return 'h:mm a'
}

/** API Response code */
const apiResponseCode = {
  successCode: 200,
  internalServerError: 500,
  badRequest: 400,
  notFound: 404,
  noContent: 204,
}

const captchaKey = {
  siteKey: '6LdmO50lAAAAABS8pQrq2wunw9W-StOWPy8hOzDJ',
  secretKey: '6LdmO50lAAAAAFO8QFfUW_DEhhW0tPPIcav6QePV',
  captchaAction: { action: 'NPSCaseSearch' },
}

function addRandomLabel() {
  const textAreaElements = document.getElementsByTagName('textarea')
  for (let i = 0; i < textAreaElements?.length; i++) {
    if (
      !document.querySelector("label[for='" + textAreaElements[i]?.id + "']")
    ) {
      var elem2 = document.createElement('label')
      elem2.innerHTML = '.'
      elem2.style.display = 'none'
      elem2.htmlFor = textAreaElements[i]?.id
      document.getElementsByTagName('body')[0].appendChild(elem2)
    }
  }
}

function browserIdentify() {
  var ua = navigator.userAgent
  var tem
  var browserName =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    []
  if (/trident/i.test(browserName[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return 'IE ' + (tem[1] || '')
  }
  /* istanbul ignore next */
  if (browserName[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
    if (tem !== null) return tem.slice(1).join(' ').replace('OPR', 'Opera')
  }
  browserName = browserName[2]
    ? [browserName[1], browserName[2]]
    : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) !== null)
    browserName.splice(1, 1, tem[1])
  return browserName[0]
}
const getCookies = () => {
  var regexPatternTrue = new RegExp('true')
  // var regexPatternFalse = new RegExp('false')
  const cookieString = decodeURIComponent(document.cookie)
  const cookieArray = cookieString.split(';')
  const cookieStorage = {}
  cookieArray?.forEach((data) => {
    if (data?.indexOf('acceptcookie=') >= 0) {
      cookieStorage.acceptcookie = regexPatternTrue.test(
        data
          ?.replace(/[ ]/g, '')
          ?.substring('acceptcookie='.length, data?.length)
      )
    }
  })
  // console.log(cookieStorage)
  return cookieStorage
}
const setCookies = (cookieObject) => {
  for (const [key, value] of Object.entries(cookieObject)) {
    document.cookie = `${key}=${encodeURIComponent(value)}`
  }
}
const convertUtcDateTimeToTimezoneDateTime = (
  ianaTimezone,
  dateTimeValue,
  type = 'DateAndTime'
) => {
  const momentWithDateTimeValue = moment(
    new Date(
      `${dateTimeValue?.toString()?.substring(0, 19)?.replace(' ', 'T')}Z`
    )
  )
  const convertedDateTimeForTimezone = momentWithDateTimeValue
    .tz(ianaTimezone)
    .format(commonDateTimeFormat(type))

  return convertedDateTimeForTimezone
}
const errorMessage = {
  invalidSearchParams:
    'The case you are searching for appears to be valid however the full details have not yet been fully imported to our system, please check again after 1 hour.',
}

const returnCurrentDateTimeBasedOnTimezone = (ianaTimezone) => {
  return DateTime?.now()?.setZone(ianaTimezone)?.toFormat(utcDateFormat('Date'))
}

const convertDateTimeToFormattedDateTime = (
  params = null,
  formateName = 'Date'
) => {
  if (params)
    return DateTime?.fromISO(params)?.toFormat(
      commonDateTimeFormat(formateName)
    )

  return params
}

const logSaveText = {
  customerPortalSearch: 'Customer Portal Search',
  eventDescriptionPortal: 'Citizen user has searched the case manually.',
  eventDescriptionQRCode: 'Citizen user has searched the case via QR code.',
}
const toastStatus = {
  error: 'error',
  success: 'success',
  warning: 'warning',
}

const getDisplayTypeOfAttachment = (fileExtension) => {
  let fileNameExtension = fileExtension?.toString()?.toLowerCase()
  let displayType = ''
  switch (fileNameExtension) {
    case 'jpg':
    case 'jpeg':
    case 'bmp':
    case 'heic':
    case 'tif':
    case 'tiff':
    case 'png':
    case 'jfif':
      displayType = 'img'
      break
    case 'pdf':
      displayType = 'pdf'
      break
    case 'docx':
    case 'doc':
      displayType = 'doc'
      break
  }
  return displayType
}
const contractTypes = {
  pcnContract: 'pcn',
}

export default {
  sortArrayOfObjects,
  handleBeforeUnload,
  confirmwindow,
  utcDateFormat,
  commonDateTimeFormat,
  apiResponseCode,
  browserIdentify,
  captchaKey,
  getCookies,
  setCookies,
  convertUtcDateTimeToTimezoneDateTime,
  addRandomLabel,
  errorMessage,
  confirmationText,
  returnCurrentDateTimeBasedOnTimezone,
  convertDateTimeToFormattedDateTime,
  logSaveText,
  toastStatus,
  getDisplayTypeOfAttachment,
  contractTypes,
}
