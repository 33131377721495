/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  This Footer component developed for display the organisation details like logo and name
*/

import React from 'react'
import './Footer.css'
import footerLogo from './logo_white.svg'
import { useNavigate } from 'react-router'
const Footer = ({ setShowCookieBanner }) => {
  const navigate = useNavigate()

  const cookiesLinkClick = () => {
    navigate('/moreinfo')
  }

  return (
    <div className="footer">
      <div className="container">
        <div className="footer__inner row">
          <div className="footer__bottom row">
            <div className="footer__logo col">
              <a href="http://wokingham.gov.uk/">
                {footerLogo?.toString()?.trim()?.length > 0 && (
                  <img
                    className="footer__logo__img"
                    src={footerLogo}
                    alt="Wokingham Council logo white"
                  />
                )}
              </a>
            </div>
            <div className="footer__content col">
              <ul className="menu">
                <li className="menu__item">
                  <a
                    className="menu__link"
                    href="http://wokingham.gov.uk/accessibility"
                  >
                    Accessibility
                  </a>
                </li>
                <li className="menu__item">
                  <a
                    className="menu__link"
                    href="https://ats-wokinghamjobs.jgp.co.uk/vacancies/list?ga_client_id=b917a54b-f079-b4b8-0a96-c449fb3620a3"
                  >
                    Careers
                  </a>
                </li>
                <li className="menu__item">
                  <a
                    className="menu__link"
                    href="http://wokingham.gov.uk/contact-us"
                  >
                    Contact us
                  </a>
                </li>
                <li className="menu__item">
                  <a
                    className="menu__link"
                    // href="http://wokingham.gov.uk/cookies-and-privacy"
                    onClick={() => {
                      cookiesLinkClick()
                    }}
                  >
                    Cookies
                  </a>
                </li>
                <li className="menu__item">
                  <a
                    className="menu__link"
                    href="https://engage.wokingham.gov.uk/en-GB/"
                  >
                    Current consultations
                  </a>
                </li>
                <li className="menu__item">
                  <a
                    className="menu__link"
                    href="http://wokingham.gov.uk/disclaimer"
                  >
                    Disclaimer
                  </a>
                </li>
                <li className="menu__item">
                  <a className="menu__link" href="https://www.gov.uk/">
                    GOV.UK
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
